/* CSS files add styling rules to your content #1da1f2*/
:root {
  --white: #fff;
  --white: #fff;
  --issue-background: #f1f8ff;
  --issue-color: #0366d6;
  --published: #279f43;
  --repo-color: #586069;
  --color: hsla(0, 0%, 100%, 0.7);
  --dark-color: #1c1e21;
  --border-color: #e8eaed;
  --background-color: #f6f8fa;
  --default-background: #eff2f4;
  --red-background: #feefe3;
  --red-color: #bf360c;
  --hover-color: #f6f8fa;
  --blue-color: #1da1f2;
  --theme-main-color: #07a092;
  --color-btn-primary-bg: rgb(46, 164, 79);
  --hover: rgba(29, 161, 242, 0.1);
  --white: #fff;
  --color: hsla(0, 0%, 100%, 0.7);
  --cool-gray-80: #38434f;
  --dark-color: #1c1e21;
  --background-color: #f6f8fa;
  --red-background: #feefe3;
  --hover-color: #f6f8fa;
  --blue-color: #1da1f2;
  --blue-hover: rgba(29, 161, 242, 0.1);
  --twitter-hover: rgba(29, 161, 242, 0.09);
  --theme-main-color: #07a092;
  --error-back: #fed859;
  --yellow-200: #fff5b1;
  --yellow-100: #fffbdd;
  --orange-200: #ffd1ac;
  --green-200: #bef5cb;
  --blue-200: #c8e1ff;
  --blue-100: #dbedff;
  --blue-600: #005cc5;
  --gray-100: #f6f8fa;
  --red-100: #ffdce0;
  --red-200: #fdaeb7;
  --color-btn-text: #0f1113;
  --color-btn-bg: #f6f8fa;
  --color-btn-bg: #f6f8fa;
  --color-btn-border: rgba(27, 31, 36, 0.15);
  --color-btn-primary-text: #ffffff;
  --color-btn-primary-bg: #2ea44f;
  --color-btn-primary-border: rgba(27, 31, 35, 0.15);
  --color-btn-primary-shadow: 0 1px 0;
  --color-accent-emphasis: #0969da;
  --color-primer-shadow-focus: 0 0 0 3px rgba(9,105,218,0.3);
  --color-canvas-subtle: #f6f8fa;
}

* {
  box-sizing: border-box;
}

body {
  background-color: var(--white);
  display: inline;
  font-family: Helvetica Neue LT, -apple-system, BlinkMacSystemFont, Segoe UI,
    Helvetica, Arial, sans-serif;
  font-size: 13px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body,
main {
  background-color: var(--white);
  margin: 0 auto;
  padding: 0;
  width: 100%;
  height: 100%;
}

h3 {
  margin: 0;
}

.user-profile h3 {
  margin-bottom: 15px;
}

#info {
  margin-bottom: 22px !important;
}

.action-button {
  display: inline-block;
  z-index: 1;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  margin-left: -4px;
  color: #444d56;
  background-color: #e1e4e8;
  border: 2px solid #fff;
  border-radius: 50%;
}

h1 {
  color: var(--dark-color);
}

a {
  text-decoration: none;
  color: var(--dark-color);
}


/* input[type="text"]:focus {
  border-color: var(--color-accent-emphasis);
  outline: none;
  box-shadow: var(--color-primer-shadow-focus);
} */

.text-gradient {
  font-weight: 600;
  background: linear-gradient(
    270.97deg,
    #ffe580 -21.36%,
    #ff7571 -2.45%,
    #ea5dad 26.84%,
    #c2a0fd 64.15%,
    #3bf0e4 108.29%,
    #b2f4b6 159.03%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-1 {
  margin-left: 3px;
  font-size: 10px;
  background: linear-gradient(
    141.27deg,
    #ff904e -4.24%,
    #ff5982 21.25%,
    #ec68f4 44.33%,
    #79e2ff 83.46%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-2 {
  background: linear-gradient(
    270.97deg,
    #ffe580 -21.36%,
    #ff7571 -2.45%,
    #ea5dad 26.84%,
    #c2a0fd 64.15%,
    #3bf0e4 108.29%,
    #b2f4b6 159.03%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.load-page {
  margin: 0 auto;
  background-color: var(--white);
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
}

canvas {
  cursor: pointer !important;
}

.home-page {
  background-color: var(--white);
  display: none;
  height: 100vh;
}

header {
  background-color: var(--dark-color);
  color: var(--white);
  height: 42px;
  line-height: 42px;
  letter-spacing: 1px;
  margin: 0 auto;
  padding: 5px;
  position: sticky;
  top: 0;
  z-index: 2;
}

nav {
  height: 100%;
  margin: 0 auto;
  width: 100%;
  max-width: 1300px;
}

.user,
.nav-list {
  display: inline-flex;
  float: right;
}

.header-link {
  text-align: center;
  line-height: 32px;
  width: 32px;
  height: 32px;
  font-weight: 600;
  color: #fff;
  white-space: nowrap;
  margin: 5px 2px;
  font-size: 16px;
}

.header-link:hover {
  border-radius: 50%;
  background-color: #333333;
}

.no-todos {
  font-size: 17px;
  margin-bottom: 0;
}

.user {
  margin-left: 5px;
}

.header-link:hover {
  color: hsla(0, 0%, 100%, 0.7) !important;
  text-decoration: none;
}

main {
  display: block;
  margin: 0 auto;
  max-width: 1300px;
  width: 100%;
}

.home-page {
  display: flex;
  width: 100%;
  max-width: 1300px;
}

#alert {
  background-color: var(--red-background);
  color: var(--red-color);
  border: none;
  font-size: 12px;
}

.info {
  background: #f1f8ff;
  border: 1px solid #c8e1ff;
  /*background-color: #f1f8ff;
  border-bottom-color: #c0d3eb;
  border: 1px solid #97c1da;
  background-color: #d8ebf8;*/
  display: block;
  padding: 5px;
  text-align: center;
  position: sticky;
  font-weight: 600;
  animation-duration: 3s;
  animation-name: slidein;
}

.link {
  border-bottom: 1.5px solid #222;
  padding-bottom: 0.5px;
}

.logo-title {
  float: left;
  font-size: 20px;
}

.text-logo-top {
  display: inline-block;
  font-size: 2em;
  font-weight: 500;
  padding: 0 2px;
  margin: 0;
  margin-bottom: 10px;
  background: #fff;
  text-align: center;
  color: #1c1e21;
}

.user-label {
  float: right;
  font-size: 13px;
  margin-right: 10px;
}

.user-photo > img {
  background-color: #e1e4e8;
  border-radius: 50%;
  border: 2px solid var(--white);
  cursor: pointer;
  float: right;
  height: 27px;
  width: 27px;
  margin-top: 5px;
}

.top-nav {
  background: #fff;
  border-bottom: 1px solid #dadce0;
  display: none;
  font-weight: 600;
  font-size: 15px;
  position: sticky;
  padding: 12px;
  top: 0;
  z-index: 12;
}

.nav-right {
  float: right;
  display: inline-flex;
}

.more {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  text-align: center;
  padding-left: 20px;
}

/* .back:hover {
  color: #1da1f2;
  background: rgb(60,64,67,0.078) /* #f1f8ff #e3fded rgba(60,64,67,0.078);;
  border-radius: 50%;
  padding: 7px;
}*/

.parent-inner {
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

.back {
  letter-spacing: 1px;
}

.parent-form {
  background-color: #fff;
  position: sticky;
  top: 48px;
  z-index: 5;
  padding: 0;
  box-sizing: border-box;
  margin: 0 auto;
}

.top-header {
  background-color: #fff;
  border-bottom: 1px solid rgb(230, 236, 240);
  padding: 10px 20px;
  font-weight: 600;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 5;
  font-size: 16px;
  margin: 0 auto;
  box-sizing: border-box;
}

.top-header h3 {
  font-size: 18px;
  margin: 0;
  letter-spacing: 0.75px;
  font-weight: 700;
  width: 100%;
  box-sizing: border-box;
}

.parent {
  border: 0;
  /* border-right: 0.5px solid var(--border-color);
  border-left: 0.5px solid var(--border-color); */
  margin: 0 auto;
  width: 100%;
  z-index: 2;
}

.times {
  height: 128px;
  overflow-y: auto;
  margin: 5px;
}

.set-time {
  color: var(--dark-color);
  font-size: 12px;
  width: 95px;
  margin: 6px 0;
  padding: 5px 8px;
}

.add-date-time {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.title {
  display: inline-block;
  width: 77.8%;
}

.time-item {
  border: 1px solid rgba(27, 31, 35, 0.2);
  cursor: pointer;
  border-radius: 10px;
  padding: 4px 7px;
  width: 75%;
  text-align: center;
  font-weight: 600;
  margin: 10px auto;
  outline: 0;
  letter-spacing: 1px;
}

.time-item:hover {
  width: 80%;
  background-color: #f1f8ff;
  border: 1px solid #c8e1ff;
}

select {
  background-color: #fff;
  color: #1c1e21;
  cursor: pointer;
  display: block;
  font-size: 13px;
  width: 90%;
  border: 0;
  margin: 10px auto;
  outline: 0;
  letter-spacing: 1px;
}

textarea,
input {
  /* background-color: #f1f1f1;
   border-bottom: 2px solid var(--dark-color);
  webkit-box-shadow: 0 0 50px 0 rgba(137, 168, 199, 0.25);
  box-shadow: 0 0 50px 0 rgba(137, 168, 199, 0.25);*/
  background-color: #fff;
  color: #1c1e21;
  border: 1px solid rgba(27, 31, 35, 0.2);
  border-radius: 4px;
  display: block;
  font-family: helvetica, arial, sans-serif;
  font-size: 13px;
  margin: 10px 0;
  padding: 7px 10px;
  outline: none;
}

textarea,
input:required {
  box-shadow: none;
}

.tasks-container::-webkit-scrollbar {
  display: none;
}

.parent-inner::-webkit-scrollbar {
  display: none;
}

.tasks-container {
  position: relative;
  margin: 0 auto;
}

.task-text {
  line-height: 1.5;
  width: 100%;
  padding: 10px 20px;
  font-size: 17px;
  font-weight: 500;
  box-sizing: border-box;
  border: 0;
  border-bottom: 1px solid var(--border-color);
  border-radius: 0;
  margin: 0;
}

.repo-text {
  line-height: 1.5;
  width: 100%;
  padding: 10px 0;
  font-size: 15px;
  font-weight: 500;
  box-sizing: border-box;
  border: 0;
  border-radius: 0;
  margin: 0;
  margin-right: 20px;
}

.select-repo {
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 1.5;
  border: 0;
  border-bottom: 1px solid var(--border-color);
  width: 100%;
  padding: 0 20px;
}

.select-repo-btn {
  background-color: var(--color-btn-bg);
  border: 1px solid var(--color-btn-border);
  color: var(--color-btn-text);
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 15px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 11px;
  align-items: center;
  padding: 3px 10px;
  border-radius: 5px;
}

.comment-label {
  border: 0;
  border-bottom: 1px solid var(--border-color);
  padding: 8px 20px;
  box-sizing: border-box;
  background-color: var(--border-color);
  display: flex;
  align-items: center;
}

.comment-body {
  color: rgb(101, 119, 134);
  outline: none;
  padding: 10px 20px;
  border: 0;
  border-bottom: 1px solid var(--border-color);
  font-size: 14px;
}

.create-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 15px;
  border: 0;
  border-bottom: 1px solid var(--border-color);
}

.date-time {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.date-icon {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 5px;
}

.time-icon {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 5px;
}

.create-btn {
  border: 1px solid var(--color-btn-border);
  background-color: var(--color-btn-bg);
  padding: 5px 10px;
  border-radius: 5px;
  color: var(--color-btn-text);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  font-size: 12px;
  font-weight: 600;
  max-width: 80px;
}

label {
  color: #1c1e21;
  display: block;
  font-size: 14px;
  font-weight: 600;
}

.date {
  height: 12px;
  float: left;
  font-size: 11px;
  margin: 0 auto;
  width: 120px;
}

.main-sidebar {
  background-color: var(--white);
  max-width: 280px;
  width: 100%;
  height: 100%;
}

.sidebar-inner {
  background-color: var(--white);
  border: 0;
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  position: fixed;
  max-width: 280px;
  height: 100%;
  width: 100%;
  z-index: 8;
}

.sidebar-inner-top {
  padding: 10px 0;
}

.sidebar-inner-bottom {
  padding: 10px 0;
}

.add,
.view {
  border-top: 1px solid var(--border-color);
}

.add > a {
  display: block;
}

.view > a {
  display: block;
}

.label-text {
  margin-left: 10px;
}

.navigate {
  /* border-bottom: 0.5px solid var(--border-color); */
  background-color: #fff;
  cursor: pointer;
  /* color:#1da1f2; */
  display: flex !important;
  flex-direction: row;
  align-items: center;
  font-size: 17px;
  font-weight: 700;
  line-height: 1.5;
  padding: 6px 10px;
}

.navigate-inner {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  padding: 8px 20px;
}

.navigate-inner:hover {
  background-color: #f1f8ff;
  color: #1da1f2;
}

.circle-btn {
  border: 1px solid rgba(27, 31, 35, 0.2);
  cursor: pointer;
  content: none;
  height: 24px;
  width: 24px;
  display: flex;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}

.button {
  background-color: #fff;
  border: 1px solid rgba(27, 31, 35, 0.2);
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  margin: 10px auto;
  outline: none;
  padding: 6px;
  text-align: center;
  width: 150px;
}

.button:hover {
  background: #f1f8ff;
  border: 1px solid #c8e1ff;
}

.hover:hover {
  background-color: #f1f8ff;
  border: 1px solid #c8e1ff;
}

button:disabled,
button[disabled] {
  font-weight: 100;
  opacity: 0.5;
}

.active {
  /* background: #f0f2f5; */
  border-radius: 30px;
  border: 0;
  border-left-color: currentcolor;
  border-left-style: none;
  border-left-width: 0px;
  color: #1da1f2;
}

.show-tip {
  display: none;
  font-size: 11px;
  margin: 0 auto;
  padding: 0 2px;
}

.svg-active {
  color: #fff;
  background-color: #e1e4e8;
  border: none;
}

.box-title {
  margin: 0 auto;
  padding-bottom: 5px;
}

.loading {
  color: #1c1e21;
  background: #fff;
  border-radius: 2px;
  border: 1px solid #dadce0;
  padding: 5px 10px;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  margin: 0 auto;
}

.code {
  font-variant-ligatures: none;
  font-family: "Fira Mono", "Andale Mono", "Consolas", monospace;
}

.syncing {
  display: none;
  margin: 0 auto;
  width: 150px;
  text-align: center;
  margin-bottom: 20px;
}

.hover-grow {
  transition: transform 0.3s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.hover-grow:hover {
  transform: scale(1.02);
}

.anim-pulse {
  animation-name: pulse;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.hello {
  float: right;
  padding: 20px;
  max-width: 650px;
  width: 100%;
  text-align: center;
}

.hello > h1 {
  font-size: 20px;
  margin: 0;
}

details summary::marker {
  display: none !important;
}

.success {
  color: #144620;
  background-color: #dcffe4;
  border-color: #34d058;
  position: absolute;
  z-index: 10;
  display: block;
  max-width: 450px;
  padding: 5px 8px;
  margin: 4px 0 0;
  font-size: 13px;
  font-weight: 400;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
}

.note {
  min-height: 17px;
  margin: 4px 0 2px;
  font-size: 12px;
  color: #586069;
}

.test-time {
  border-radius: 30px;
  cursor: pointer;
  width: 100%;
  max-width: 150px;
  text-align: center;
  display: inline-block;
  margin-left: 5px;
  padding: 8.3px 15px;
}

.pick-date {
  border-radius: 30px;
  cursor: pointer;
  width: 100%;
  max-width: 150px;
  padding: 6px 15px;
  padding-right: 11px;
}

.no-tasks {
  background: #fff;
  text-align: center;
  margin: 0 auto;
  margin-top: 50px;
  left: 0;
  right: 0;
  z-index: 0;
}

.no-tasks-title {
  font-size: 1.2em;
  font-weight: 600;
}

.top-header-inbox {
  cursor: pointer;
  text-align: right;
  width: 35px;
}

.top-header-inbox:hover {
  color: #1da1f2;
}

@keyframes pulse {
  0% {
    opacity: 0.3;
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

footer {
  display: none;
  background-color: #fff;
  border-top: 1px solid var(--border-color);
  padding: 20px 10px;
  text-align: center;
  height: 20px;
}

footer > a {
  color: #0366d6;
  margin: 0 7px;
}

.mobile {
  display: none;
}

.is-desktop {
  display: none;
}

.created-btn-inner {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.created-success {
  max-width: 100px;
  padding: 2.5px 5px;
  border: 0;
  border-radius: 0;
  color: var(--color-btn-primary-text);
  background-color: var(--color-btn-primary-bg);
  /* background-color: #f1f8ff;
  color: #1da1f2; */
  font-weight: 600;
  font-size: 13px;
  position: relative;
  width: 100%;
  outline: none;
}

.app-alert {
  background-color: #fff;
  color: var(--red-color);
  display: flex;
  align-items: center;
  padding: 5px 20px;
  margin: 0 auto;
  border-bottom: 1px solid var(--border-color);
}

.footnote {
  color: rgb(101, 119, 134);
  margin: 5px auto;
}

.pc-AvatarItem {
  cursor: pointer;
  height: 25px !important;
  width: 25px !important;
}

.pc-AvatarStack--two {
  height: 25px !important;
}

.set-expand {
  display: none;
}

@media (max-width: 1150px) {
  .set-expand {
    display: block;
  }
  .page-aside {
    display: none;
  }
}

@media (max-width: 980px) {
  .not-mobile {
    display: none !important;
  }
  .is-desktop {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
}

@media (max-width: 415px) {
  main {
    margin: 0 auto;
  }
  .hide-header {
    display: none;
  }

  .home-main {
    padding: 0px;
  }

  .top-header {
    padding: 10px 20px;
  }

  nav {
    margin: 0 10px;
  }

  .user-label {
    display: none;
  }

  .navigate {
    display: none !important;
  }

  .sidebar-inner {
    display: none !important;
  }

  .productivity {
    margin: 75px auto !important;
  }

  .parent {
    float: none !important;
  }

  .form {
    padding: 0px 20px;
  }

  input {
    margin: 15px 0;
    font-size: 15px;
  }

  form .button {
    border-radius: 30px;
  }

  .linked {
    display: block;
  }

  .title {
    border-bottom: 1px solid var(--border-color) !important;
    font-size: 18px;
  }

  .title,
  .repository {
    display: block;
    width: 100%;
    padding: 8px 0;
    border: 0;
    border-radius: 0;
  }

  .repository {
    display: none;
    margin-bottom: 20px;
  }

  .title:hover {
    background-color: #fff;
    border: 0;
    border-bottom: 1px solid var(--border-color);
  }

  .title:focus {
    font-size: 15px;
  }

  .repository:hover {
    background-color: #fff;
    border: 0;
    border-bottom: 1px solid var(--border-color);
  }

  .mobile {
    display: block;
    margin: 20px auto;
  }

  .desktop {
    display: none;
  }

  .top-nav {
    display: block;
  }

  .parent {
    padding: 0px;
    border: none;
    border-radius: 0;
    margin: 0 auto;
  }
}

.mobile-nav {
  border-radius: 4px;
  cursor: pointer;
  display: block;
  font-size: 14px;
  margin: 10px auto;
  padding: 8px 10px;
  border: 1px solid #e8eaed;
}

.desc {
  animation-duration: 2;
  animation-name: slidein;
}

.news-alert {
  padding: 15px;
  margin: 30px auto;
  text-align: center;
}

.news-alert-title {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

.news-alert-desc {
  text-align: center;
  font-size: 15px;
  margin: 5px auto;
  max-width: 350px;
}

.news-alert-footer {
  display: block;
  width: fit-content;
  margin: 0 auto;
  margin-top: 10px;
}

.repo-list-container {
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 100%;
  overflow: auto;
}

.repo-list-item {
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  padding: 8px 20px;
  border-top: 1px solid var(--border-color);
}

.repo-list-item:hover {
  background-color: rgb(246, 248, 250);
}

.repo-list-item:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.repo-list-item:first-child {
  border-top: 0;
}

.repo-list-item:last-child {
  border-bottom: 0;
}

.repo-list-item-left {
  width: 100%;
}

.repo-list-item-right {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.repo-list-item-icon {
  color: #657786;
  display: flex;
  align-items: center;
  flex-direction: row;
}

@keyframes slidein {
  from {
    margin-right: 10%;
  }

  to {
    margin-right: 0%;
  }
}

@media (min-width: 800px) {
  ::-webkit-scrollbar {
    width: 3px;
  }

  /* track */
  ::-webkit-scrollbar-track {
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  /* handle */
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 5px;
    background: #e1e4e8;
  }

  ::-webkit-scrollbar-thumb:window-inactive {
    background: none;
  }
}
