.page-aside {
  display: flex;
  flex-direction: column;
  max-width: 350px;
  width: 100%;
  order: 2;
}

.page-aside-inner {
  border-left: 1px solid var(--border-color);
  /* border-right: 1px solid var(--border-color); */
  position: fixed;
  display: flex;
  width: 100%;
  max-width: 350px;
  padding: 20px;
  padding-right: 0;
  height: 100%;
  flex-direction: column;
  box-sizing: border-box;
}

.page-aside-tools {
  border-radius: 7px;
  border: 1px solid var(--border-color);
  align-items: stretch;
  margin: 0;
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.page-aside-more {
  border-radius: 7px;
  border: 1px solid var(--border-color);
  align-items: stretch;
  min-height: 100px;
  margin: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.page-aside-top {
  display: block;
}

.page-aside-title {
  border-bottom: 1px solid var(--border-color);
  text-align: left;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.page-aside-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #657786;
  font-size: 13.5px;
  border-bottom: 1px solid var(--border-color);
  text-align: left;
  padding: 10px 20px;
}

.page-aside-group:last-child {
  border-bottom: 0;
}

.page-aside-group-right {
  cursor: pointer;
  text-align: right;
  width: 20%;
}

.page-aside-group-right:hover {
  color: #1da1f2;
}

.page-aside-group-left {
  cursor: pointer;
  width: 100%;
}

@media (max-width: 1200px) {
  .page-aside-inner {
    padding-right: 20px;
  }
}
