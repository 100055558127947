.task-item {
  border-bottom: 1px solid var(--border-color);
  padding: 10px 20px;
}

.task-item-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.task-item-date {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: rgb(101, 119, 134);
  font-size: 11px;
  width: 100%;
  margin-left: 2px;
}

.task-repo-label {
  background-color: var(--color-btn-bg);
  color: var(--color-btn-text);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 10px;
  border-radius: 5px;
  font-size: 11px;
}

.task-repo-label-link {
  border: 1px solid var(--color-btn-border);
  font-size: 11px;
}

.task-item-more {
  cursor: pointer;
}

.task-item-content {
  line-height: 1.5;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
}

.task-item-title::first-letter {
  text-transform: capitalize;
}

.task-item-icon {
  width: 30px;
}

.task-item-content-text {
  margin-left: 10px;
  width: 100%;
}

.marker {
  background-color: rgb(57, 199, 157);
  border-radius: 50%;
  content: "";
  height: 11px;
  margin-right: 6px;
  position: relative;
  transition: all ease 250ms;
  width: 11px;
}

.check-icon {
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  height: 28px;
  width: 28px;
  margin: 0;
  border-radius: 50%;
  -webkit-user-select: none;
  user-select: none;
  font-size: 12px;
  font-weight: 600;
}

.check-icon:hover {
  color: #fff;
  border: 1.5px solid rgb(46, 164, 79);
  background: rgb(46, 164, 79);
}

.board-label,
.epic,
.low,
.medium,
.person {
  display: flex;
  justify-content: center;
  color: #000;
  font-size: 10px;
  font-weight: 700;
  align-items: center;
  min-width: 70px;
  border-radius: 4px;
  padding: 4px;
  text-transform: uppercase;
}

.person {
  background-color: var(--blue-100);
}

.low {
  background-color: var(--green-200);
  margin-left: 4px;
}

.board-label {
  border: 1px solid var(--color-btn-border);
}

.medium {
  background-color: var(--orange-200);
  margin-left: 4px;
}

.epic {
  background-color: var(--red-200);
  margin-left: 4px;
}

.task-controls {
  display: flex;
  align-items: center;
  margin: 5px auto;
}

.task-item-more {
  display: none;
}

.task-item-archive {
  margin-left: 10px;
  border: none;
  width: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  font-size: 12px;
  font-weight: 600;
}

.task-item-archive :hover {
  color: red;
}

.task-item-controls {
  display: flex;
  align-items: center;
}

.task-edit-btn {
  background-color: var(--color-btn-primary-bg);
  color: var(--white);
  margin-left: 10px;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  font-size: 11px;
  font-weight: 600;
  padding: 4px;
}

.task-item-edit-input {
  padding: 9px 12px;
  width: 100%;
}

.task-item-edit-buttons {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
}

.task-item-edit-button {
  background-color: var(--color-btn-bg);
  color: var(--color-btn-text);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 10px;
  border-radius: 5px;
  font-size: 11px;
  width: 80px;
}

.task-item-edit-button-text {
  margin-left: 5px;
}

.task-item-edit-button:last-child {
  margin-left: 10px;
}

.task-item-edit-button:hover {
  color: #fff;
  background-color: var(--color-btn-primary-bg);
  border: 1px solid var(--color-btn-primary-bg);
}
