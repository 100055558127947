html {
  --white: #fff;
  --color: hsla(0, 0%, 100%, 0.7);
  --dark-color: #24292e;
  --border-color: #eff3f6 /*#e1e4e88*/;
}

.b1 {
  background-color: #fff /*#f6f8fa*/;
  font-size: 13px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 auto;
  padding: 0;
  display: flex;
  align-items: center;
  height: 100vh;
}

#hello,
h2 {
  color: #222;
}

.a {
  text-decoration: none;
  color: #0366d6;
}

.main1 {
  max-width: 900px;
  margin: 50px auto;
  padding: 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.winky {
  width: 120px;
  /* 
  height: 120px;
  border-radius: 50%;
  border: 2px solid;*/
}

.mobile {
  display: none;
}

.auth {
  margin: 0 auto;
  padding: 40px 15px;
  max-width: 275px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 10px;
}

.text-logo {
  display: inline-block;
  font-size: 4.5em;
  font-weight: 500;
  padding: 0 2px;
  margin: 0;
  background: #fff;
  text-align: center;
  color: #1c1e21;
}

.text-logo-circle {
  display: inline-block;
  margin: 0;
  border-radius: 50%;
  padding: 0;
  width: 60px;
}

.button1 {
  color: var(--dark-color);
  background-color: var(--border-color);
  border: 1.5px solid var(--dark-color);
  border-radius: 4px;
  box-shadow: 4px 4px var(--dark-color);
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  margin: 10px auto;
  outline: none;
  padding: 7px;
  text-align: center;
  width: 175px;
}

.button1:hover {
  background-color: #fff;
  box-shadow: 0 0 var(--dark-color);
}

.col-md-7 {
  /* https://khanacademyannualreport.org/images/highlight-bg-2.svg */
  /* background-image: url(https://khanacademyannualreport.org/images/highlight-bg-2.svg);*/
  font-size: 18px;
  box-sizing: border-box;
  width: 58.33333%;
  text-align: left;
  padding-right: 24px;
  padding-left: 24px;
}

#hello {
  background-image: url("../images/back.svg");
  background-size: cover;
  line-height: 1.2;
  text-align: left;
  margin: 0.67em 0;
  margin-top: 0;
  font-weight: 500;
  font-size: 55px;
  margin-bottom: 16px;
}

.lead {
  line-height: 1.5;
  color: #222 /*hsla(0, 0%, 100%, 0.6)*/;
  text-align: left;
  box-sizing: border-box;
  margin-top: 0;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 24px;
}

footer {
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  background-color: #fff;
  border-top: 1px solid var(--border-color);
  padding: 25px 10px;
  margin: 0 auto;
  text-align: center;
  position: fixed;
}

footer > a {
  color: #0366d6;
  margin: 0 7px;
}

@media (max-width: 799px) {
  #hello {
    font-size: 40px;
  }

  .col-md-7 {
    width: 100%;
  }

  .mobile {
    display: block;
  }

  .lead,
  .col-md-7 {
    display: none;
  }

  .auth {
    border: 0;
    box-shadow: none;
    margin: auto;
    top: 0;
    position: absolute;
    padding: 30px 20px;
    right: 0;
    bottom: 50px;
    left: 0;
    height: 275px;
  }

  form {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .flex1 {
    align-items: center;
    display: flex;
    margin-right: -24px;
    margin-left: -24px;
  }
}

@media (min-width: 544px) {
  .main1 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
}

@media (min-width: 800px) {
  ::-webkit-scrollbar {
    width: 3px;
  }

  /* track */
  ::-webkit-scrollbar-track {
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  /* handle */
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 5px;
    background: #e1e4e8;
  }

  ::-webkit-scrollbar-thumb:window-inactive {
    background: none;
  }
}
