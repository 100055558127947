.top-alert {
    display: none;
    font-weight: 600;
    font-size: 13px;
    position: sticky;
    padding: 10px 0;
    text-align: center;
    bottom: 50px;
    z-index: 13;
  }
  
  .top-alert > p {
    margin: 0 auto;
  }

  .flash-error {
    color: #86181d;
    background-color: #ffdce0;
  }

  .flash-success {
    color: #165c26;
    background-color: #dcffe4;
  }

  .flash-warn {
    color: #735c0f;
    background-color: #fffbdd;
    border-color: rgba(27,31,35,0.15);
}