.date-picker {
    color: transparent !important;
    -webkit-appearance: none;
    font-size: 12px;
    margin: 6px 0;
    font-weight: 600;
    display: block;
    position: relative;
    border: 1px solid rgba(27, 31, 35, 0.2);
    border-radius: 4px;
    background: white
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 20 22'%3E%3Cg fill='white' fill-rule='evenodd' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' transform='translate(1 1)'%3E%3Crect width='18' height='15' y='2' rx='2'/%3E%3Cpath d='M13 0L13 4M5 0L5 4M0 8L18 8'/%3E%3C/g%3E%3C/svg%3E")
      center no-repeat;
    cursor: pointer;
    width: 12px;
    height: 12px;
  }
  .date-picker:focus {
    outline: none;
  }
  .date-picker::-webkit-datetime-edit-month-field:hover,
  ::-webkit-datetime-edit-day-field:hover,
  ::-webkit-datetime-edit-year-field:hover {
    background: rgba(0, 120, 250, 0.1);
  }
  
  .date-picker::-webkit-datetime-edit-text {
    opacity: 0;
  }
  
  .date-picker::-webkit-clear-button,
  ::-webkit-inner-spin-button {
    display: none;
  }
  
  .date-picker::-webkit-calendar-picker-indicator {
    color: transparent !important;
    -webkit-appearance: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    background: #fff;
  }  