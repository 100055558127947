.productivity {
    text-align: center;
  }
  
.box-title {
    font-size: 16px;
  }
  
.count {
    display: inline-block;
    padding: 2px 0;
    font-size: 16px;
    font-weight: 800;
    width: 60px;
    line-height: 1;
    color: #050505;
    border-radius: 5px;
  }
  
.progress {
    display: flex;
    overflow: hidden;
    background-color: #e1e4e8;
    border-radius: 2px;
    font-weight: 600;
    height: 20px;
    text-align: center;
    letter-spacing: 1px;
  }
  
.timely2 {
    background-color: rgb(57, 199, 157);
  }
  
.due2 {
    background-color: rgb(255, 176, 176);
  }
  
.late1 {
    background-color: #e1e4e8;
  }
  
.chart {
  margin: 30px auto;
  }