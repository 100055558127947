.todos {
  display: block;
  float: right;
  margin-bottom: 50px;
  max-width: 650px;
  line-height: 1.5;
  width: 100%;
}

.story {
  margin-top: 10px;
}

.story-content {
  border: 1px solid rgb(230, 236, 240);
  border-radius: 3px;
  cursor: pointer;
  padding: 5px 12px;
}

.desc {
  display: flex;
  font-size: 12px;
  line-height: 1.5;
  margin: 5px auto;
}

.desc-text {
  width: 90%;
}

.desc-icon {
  width: 10%;
  text-align: right;
  display: inline-grid;
  align-content: center;
}

.title {
  font-size: 15px;
  margin-top: 10px;
  padding: 15px 10px;
}

.issue-comments {
  display: none;
}

.issue-number,
.issue-comments {
  float: right;
}

/*.link {
  border-bottom: 1px solid #000;
  padding-bottom: 1px;
}*/

button {
  background-color: #fff;
  border: 1px solid rgba(27, 31, 35, 0.2);
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin: 10px auto;
  outline: none;
  padding: 6px;
  text-align: center;
  width: 120px;
}

.action-button {
  background-color: #e1e4e8;
  border-radius: 50%;
  display: inline-block;
  font-weight: 600;
  margin-left: 5px;
  text-align: center;
  vertical-align: middle;
  width: 24px;
}

.github-button {
  background-color: #279f43;
  color: #fff;
  border: 2px solid #279f43;
}

.due-date {
  /* border-left: 3px solid var(--dark-color); */
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.due {
  display: inline-block;
  font-size: 15px;
  background: #e1e4e8;
  padding: 3px;
  text-align: center;
  border-radius: 50%;
  width: 23px;
}

.success {
  color: #fff;
  background: green;
  padding: 3px;
  border-radius: 3px;
}

.repo {
  color: var(--repo-color);
  font-size: 12px;
}

.none {
  margin: 0 auto;
  padding-bottom: 19px;
}

.due-status {
  margin: 0 auto;
  padding: 15.5px 20px;
  border-bottom: 1px solid var(--border-color);
  display: block;
  font: 12px "SFMono-Regular", Consolas, "Liberation Mono", Menlo, monospace;
}

.timely,
.late {
  border-radius: 1.5px;
  padding: 3px 20px;
}

.timely {
  background-color: rgb(57, 199, 157);
}

.late {
  background-color: rgb(255, 176, 176);
}

.modal {
  max-width: 365px;
  transition: all 2s ease;
}

.state {
  display: block;
  float: right;
  font-weight: 600;
}

.bottom {
  bottom: 45%;
  border-radius: 4px;
  left: 0;
  right: 0;
  display: none;
  background-color: #fff;
  border: 1px solid #c8e1ff;
  margin: 0 auto;
  text-align: center;
  position: fixed;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
}

.controls,
.save {
  display: flex;
  margin: 0 auto;
}

.edit-save {
  cursor: pointer;
  font-weight: 600;
}

.edit-date {
  width: 50%;
}

.modal-flex {
  display: flex;
  flex-direction: row;
}

.close {
  cursor: pointer;
  font-size: 13px;
  width: 50%;
  padding: 6px;
  font-weight: 600;
}

.close:hover {
  cursor: pointer;
  color: #0366d6;
}

.close-1,
.close-2 {
  border-right: 0.5px solid #c8e1ff;
  width: 30%;
}

.close-3 {
  width: 30%;
}

.edit {
  float: right;
  font-size: 12px;
  font-weight: 600;
}

.detail {
  padding: 0;
}

.box-left {
  border-right: 0.5px solid #c8e1ff;
}

.detail-action {
  cursor: pointer;
  font-size: 13px;
  width: 50%;
  padding: 6px;
  font-weight: 600;
}

.detail-action:hover {
  color: var(--blue-color);
}

.mobile-status {
  display: none;
  font-weight: 400;
  font: 12px "SFMono-Regular", Consolas, "Liberation Mono", Menlo, monospace;
  float: right;
}

.refresh {
  color: #fff;
  margin-left: 8px;
  display: inline-block;
  font-size: 12px;
  outline: none;
  padding: 5px auto;
  width: 75px;
  border-bottom: 2px solid #fff;
}

.refresh:hover {
  color: #fff;
}

.bottom-menu {
  border-top: 1px solid #dadce0;
  display: none;
  background: #fff;
  bottom: 0;
  height: 250px;
  left: 0;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 98;
}

.menu-item {
  padding: 5px 0;
  text-align: center;
  margin: 10px 0;
  border-bottom: 1px solid #dadce0;
}

.bottom-menu h3 {
  margin: 5px 0;
}

.bottom-menu p {
  font-size: 14px;
}

.bottom-menu p {
  margin: 0;
  text-align: center;
}

.logo-img {
  display: inline-block;
  border-radius: 50%;
  width: 35px;
}

.inner-menu {
  padding: 15px;
}

.menu-btn {
  background-color: rgb(230, 236, 240);
  cursor: pointer;
  padding: 8px 10px;
  margin: 0 15px;
  border-radius: 20px;
  text-align: center;
  font-weight: 600;
}

@media (max-width: 799px) {
  .todos {
    margin-top: 10px;
    margin-bottom: 75px;
  }

  .bottom {
    margin: 0 10px;
  }

  .due-status {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .info {
    margin-bottom: 10px;
  }
  .story-content {
    padding: 5px 10px;
  }
}
