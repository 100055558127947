.TimelineItem {
    position: relative;
    display: flex;
    padding: 16px 0;
    margin-left: 13px;
}

.TimelineItem p {
    padding: 0;
    margin: 0;
}

.TimelineItem::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 2px;
  content: "";
  background-color: #e1e4e8;
}
.TimelineItem:target .TimelineItem-badge {
  border-color: #2188ff;
  box-shadow: 0 0 0.2em #c8e1ff;
}
.TimelineItem-badge {
  position: relative;
  z-index: 1;
  display: flex;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  margin-left: -15px;
  color: #444d56;
  align-items: center;
  background-color: #e1e4e8;
  border: 2px solid #fff;
  border-radius: 50%;
  justify-content: center;
  flex-shrink: 0;
}
.TimelineItem-body {
  min-width: 0;
  max-width: 100%;
  margin-top: 4px;
  flex: auto;
}
.TimelineItem-avatar {
  position: absolute;
  left: -72px;
  z-index: 1;
}
.TimelineItem-break {
  position: relative;
  z-index: 1;
  height: 24px;
  margin: 0;
  margin-bottom: -16px;
  margin-left: -56px;
  background-color: #fff;
  border: 0;
  border-top: 4px solid #e1e4e8;
}
.TimelineItem--condensed {
  padding-top: 4px;
  padding-bottom: 0;
}
.TimelineItem--condensed:last-child {
  padding-bottom: 16px;
}
.TimelineItem--condensed .TimelineItem-badge {
  height: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
  color: #959da5;
  background-color: #fff;
  border: 0;
}