.anim-pulse {
    animation-name:pulse;
    animation-duration:2s;
    animation-timing-function:linear;
    animation-iteration-count:infinite
   }

   @keyframes pulse {
    0% {
     opacity:0.3
    }
    10% {
     opacity:1
    }
    100% {
     opacity:0.3
    }
   }

   .anim-pulse-in {
    animation-name:pulse-in;
    animation-duration:0.5s
   }

   @keyframes pulse-in {
    0% {
     transform:scale3d(1, 1, 1)
    }
    50% {
     transform:scale3d(1.1, 1.1, 1.1)
    }
    100% {
     transform:scale3d(1, 1, 1)
    }
   }

   .anim-fade-in {
    animation-name:fade-in;
    animation-duration:1.5s;
    animation-timing-function:ease-in-out
   }

   .anim-fade-in.fast {
    animation-duration:500ms
   }

   @keyframes fade-in {
    0% {
     opacity:0
    }
    100% {
     opacity:1
    }
   }