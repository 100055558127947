.checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 5px;
}

.checkbox-label {
  font-size: 13px;
  display: block;
  border: none;
  outline: none;
  margin-left: 5px;
  font-weight: 600;
}

.checkmark {
  cursor: pointer;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  padding: 3px;
  border-radius: 5px;
  border: 1px solid rgba(27, 31, 35, 0.2);
}

.checkbox-check {
  border: 1px solid var(--color-btn-primary-bg);
  background-color: var(--color-btn-primary-bg);
}
