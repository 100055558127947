html {
  --back-color: #f1f1f1;
  --color: #222;
}

body {
  margin: 0 auto;
  line-height: 1.5;
}

.doc-page {
  padding: 20px;
}

.docs-title {
  font-size: 17px;
  font-weight: 700;
}

h2,
h3 {
  margin: 0 auto;
  margin-top: 10px;
}

small {
  font-size: 11px;
  padding: 5px 20px;
  background-color: #e1e4e8;
  font-weight: 600;
}

.badge {
  display: inline-block;
  z-index: 1;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  margin-left: -4px;
  color: #444d56;
  background-color: #e1e4e8;
  border: 2px solid #fff;
  border-radius: 50%;
}


article p {
  font-size: 14px;
}

.docs {
  padding: 20px;
  display: inline-block;
  margin: 45px auto;
  border-radius: 7px;
  border: 1px solid rgb(230, 236, 240);
  line-height: 1.5;
  max-width: 550px;
}

.desc {
  margin: 0;
  border-radius: 1px;
  border-bottom: 1px solid rgb(230, 236, 240);
  padding: 10px 0;
}
.handy {
  display: flex;
  flex-direction: column;
}

.docs > p:last-child {
  border: none;
}

@media (max-width: 799px) {
  .docs {
    margin-top: 20px;
  }
}

.bg-green {
  background-color: #28a745 !important;
}
.text-white {
  color: #fff !important;
}
