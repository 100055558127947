.search-component {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #24292e;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: right 8px center;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  outline: none;
  box-shadow: inset 0 2px 0 rgb(225 228 232 / 20%);
  padding: 0 5px;
  margin: 0 auto;
  width: 100%;
}

.search-component-search {
  margin: 0px auto;
  width: 85%;
}

.search-component:focus-within {
  box-shadow: rgba#dcffe4 0px 2px 0px inset, #dcffe4 0px 0px 0px 0.2em;
}

.search-icon {
  align-self: center;
  color: rgb(149, 157, 165);
  margin: 0 auto;
  margin-left: 8px;
  flex-shrink: 0;
}

.search-input {
  border: 0px;
  font-size: 14px;
  padding: 0 10px;
  background-color: transparent;
  -webkit-appearance: none;
  appearance: none;
  color: inherit;
  width: 100%;
}
