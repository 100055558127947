.sample {
  margin: 25px auto;
  border-radius: 7px;
  border: 1px solid #dadce0;
  max-width: 800px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji;
  background-color: #fff;
  margin: 20px auto;
  padding: 0;
  line-height: 1.5;
}

.sample small {
  font-size: 11px;
  padding: 5px 20px;
  background-color: #e1e4e8;
  font-weight: 600;
}

.sample .header,
article {
  padding: 20px;
}

.sample .header {
  border-bottom: 1px solid #dadce0;
}

.sample p,
ul {
  font-size: 14px;
}

a {
  color: black;
  text-decoration: none;
}

.sample .assert {
  border-top: 1px solid #dadce0;
  font-weight: 600;
  text-align: center;
}

.sample .navigate {
  border-top: 1px solid #dadce0;
  font-weight: 600;
  text-align: center;
  padding: 10px;
}

.sample .navigate > a:hover {
  background-color: white;
}

.sample .front,
.back {
  margin: 2px;
  display: inline-block;
  text-align: center;
  background: #f1f8ff /*#e3fded*/;
  border: 1px solid #c8e1ff;
  width: 100px;
  padding: 2px 10px;
  border-radius: 5px;
}

.sample .form {
  display: block;
  padding: 20px 0;
  margin: 0 auto;
  max-width: 400px;
}

.sample .button {
  border: none;
  padding: 8px;
  font-size: 15px;
  cursor: pointer;
}

.sample label {
  font-size: 15px !important;
  font-weight: 600;
}

.sample input,
label {
  font-size: 13px;
  display: block;
  border: none;
  outline: none;
  margin: 8px auto;
}

.sample input {
  border-bottom: 2px solid #000;
  padding: 8px 0;
  width: 100%;
}
.terms-footer {
  position: sticky;
  bottom: 15px;
  background-color: #fff;
  max-width: 300px;
  text-align: center;
  margin: 0 auto;
  padding: 10px;
  z-index: 5;
  border-radius: 10px;
  border: 1px solid var(--border-color);
  font-weight: 600;
  box-shadow: 0 48px 100px 0 rgb(17 12 46 - 15%);
}

.terms-footer .back,
.front {
  margin: 2px 10px;
  width: 75px;
}

@media (max-width: 799px) {
  .sample {
    border-radius: 0;
    border: none;
    margin: 25px auto;
    margin-top: 0;
  }
}

@media (min-width: 800px) {
  ::-webkit-scrollbar {
    width: 3px;
  }

  /* track */
  ::-webkit-scrollbar-track {
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  /* handle */
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 5px;
    background: #e1e4e8;
  }

  ::-webkit-scrollbar-thumb:window-inactive {
    background: none;
  }
}
