.user-name {
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  margin: 10px auto;
}

.user-bio {
  text-align: center;
  padding: 0 15px;
  margin: 0 auto;
}

.create-title {
  display: none;
  font-size: 18px;
  margin: 10px 0;
}

.title-two {
  font-size: 18px;
  margin: 10px 0;
  font-weight: 600;
}

.profile-input {
  font-size: 15px;
  display: block;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  width: 100%;
  max-width: 400px;
}

.profile-form {
  padding: 20px;
}

.text-holder {
  margin-left: 5px;
}

.account-item {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  padding: 10px 20px;
  line-height: 1.5;
}

.account-item-left {
  width: 100%;
}

.account-item-right {
  text-align: right;
  width: 40px;
}

.account-name {
  color: #657786;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
}

.account-input {
  font-size: 14px;
  box-sizing: border-box;
  width: 100%;
  padding: 8px 10px;
  line-height: 1.5;
  font-weight: 500;
  margin: 0;
  margin-bottom: 10px;
}

.account-label {
  font-size: 16px;
  margin: 0;
}

.account-btn {
  background-color: #1ba1f2;
  font-size: 15px;
  color: white;
  padding: 8px 11px;
  margin: 0 auto;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  width: 100px;
  font-weight: 800;
  outline: none;
}

.cancelbtn {
  width: auto;
  padding: 10px 18px;
  background-color: #f44336;
}

.account-page-container {
  padding: 20px;
}

span.psw {
  float: right;
  padding-top: 16px;
}

.account-page-flexbox {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding-top: 20px;
  font-size: 16px;
  cursor: pointer;
}

.account-page-back {
  padding-left: 10px;
}

span .left-h1 {
  padding: unset;
}

.fa-arrow-left {
  color: #1ba1f2;
}

.back-arrow {
  color: #657786;
  margin-right: 5px;
}

@media (max-width: 768px) {
  .left-header {
    display: block;
  }
  .update-account .right-top {
    display: none;
  }
}
