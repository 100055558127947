.dropdown {
    display: none;
    position: relative;
    line-height: 1.5;
    letter-spacing: normal;
}

.dropdown:hover+.dropdown-menu {
    display: block;
}

.dropdown-menu:hover {
    display: block;
}

.dropdown-menu {
    display: none;
    color: #24292e;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 100;
    width: 275px;
    margin-top: 2px;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e1e4e8;
    border-radius: 4px;
    box-shadow: 0 8px 24px rgba(149, 157, 165, 0.2);
}

.dropdown-menu::before,
.dropdown-menu::after {
    position: absolute;
    display: inline-block;
    content: "";
}

.dropdown-menu::before {
    border: 8px solid transparent;
    border-bottom-color: rgba(27, 31, 35, 0.15);
}

.dropdown-menu::after {
    border: 7px solid transparent;
    border-bottom-color: #fff;
}

.dropdown-signout {
    width: 100%;
    text-align: left;
    background: none;
    border: 0;
}

.dropdown-menu-sw {
    position: relative;
    right: 0;
    float: right;
}

.dropdown-menu-sw::before {
    top: -16px;
    right: 9px;
    left: auto;
}

.dropdown-menu-sw::after {
    top: -14px;
    right: 10px;
    left: auto;
}

.drop-item {
    border-bottom: 0.5px solid var(--border-color);
    font-size: 13px;
    padding: 7px 15px;
    text-overflow: ellipsis;
    white-space: nowrap;
    letter-spacing: normal;
}

.drop-logout {
    border: 0;
    cursor: pointer;
    font-weight: 600;
}

.drop-logout:hover {
    background-color: #f1f8ff;
    color: var(--blue-color);
    border-radius: 4px;
}

.drop-link {
    display: inline-block;
    width: 100%;
}