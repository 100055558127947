.side-top {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-direction: row;
  border-bottom: 1px solid var(--border-color);
  padding: 20px 15px;
  height: 100%;
  max-height: 94px;
}

.side-avatar {
  margin-right: 10px;
  border: 0.8px solid var(--border-color);
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 50px;
  width: 50px;
}

.side-name {
  font-size: 16px;
  color: rgb(20, 23, 26);
  font-weight: 600;
}

.side-email {
  display: flex;
  align-items: center;
  color: rgb(101, 119, 134);
  flex-direction: row;
  font-size: 13.5px;
  margin: 2px 0;
}

.side-email-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color: rgb(29, 161, 242);
}

.side-email-text {
  margin-left: 5px;
  flex-direction: row;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  max-width: 150px;
  font-size: 12px;
}
