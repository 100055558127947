.modal-alert-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.33);
  position: fixed;
  inset: 0px;
  overflow: auto;
  opacity: 0;
  animation: modal-alert-fadeIn 0.5s 0.2s forwards;
}

.modal-alert {
  top: 10%;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  align-items: stretch;
  border: 0 solid black;
  box-sizing: border-box;
  width: 100%;
  max-width: 500px;
  max-height: 550px;
  margin: 0 auto;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  position: relative;
}

.modal-icon {
  cursor: pointer;
  margin: 0;
  font-weight: 800;
  z-index: 5;
  text-align: right;
}

.modal-icon:hover {
  color: var(--color-accent-emphasis)
}

.modal-title {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-weight: 600;
  border-bottom: 1px solid var(--border-color);
  padding: 10px 20px;
  background-color: var(--color-canvas-subtle);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.modal-title-content {
  font-size: 15px;
  width: 100%;
}

.modal-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.modal-alert-body > h1 {
  margin-top: 0;
  font-size: 22px;
  margin-bottom: 8px;
}

.modal-alert-body > h3 {
  margin: 0;
  font-size: 16px;
}

.modal-alert-button-group {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.modal-alert-button-group > button {
  outline: none;
  border: none;
  background: #f1f8ff /*#e3fded*/;
  border: 1px solid #c8e1ff;
  display: inline-block;
  padding: 6px 18px;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}

.modal-content {
  padding: 10px 20px;
  font-size: 14px;
}

.modal-content label {
  margin-top: 0;
}

.modal-share-link {
  cursor: pointer;
  margin: 8px 0;
}

.modal-share-link-icon {
  margin-left: 5px;
}

.modal-share-link-icon:hover {
  color: var(--published);
}

.issue-comment {
  max-width: 410px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  color: #1c1e21;
  border: 1px solid rgba(27, 31, 35, 0.2);
  border-radius: 4px;
  display: block;
  font-family: helvetica, arial, sans-serif;
  font-size: 14px;
  padding: 10px;
  outline: none;
}

.comment-btn {
  color: var(--color-btn-primary-text);
  background-color: var(--color-btn-primary-bg);
  border-color: var(--color-btn-primary-border);
  box-shadow: var(--color-btn-primary-shadow),
    var(--color-btn-primary-inset-shadow);
  font-weight: 600;
  position: relative;
  display: inline-block;
  padding: 5px 16px;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 6px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
}

.modal-bio {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
}

.modal-bio-avatar {
  border: 0;
  border-radius: 50%;
  height: 60px;
  width: 60px;
}

.modal-bio-desc {
  padding: 0 14px;
  box-sizing: border-box;
  width: 100%;
}

.modal-bio-label {
  color: black;
  display: inline-block;
  padding: 3px 5px;
  margin: 0;
  margin-top: 7px;
}

.modal-bio-social {
  color: #1da1f2;
  display: inline-block;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.modal-filters {
  display: flex;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;
  border: 0;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}

.modal-filter {
  cursor: pointer;
  text-align: center;
  padding: 8px 0;
  font-weight: 600;
  width: 33.333333%;
}

.modal-filter:hover {
  background-color: rgb(246, 248, 250);
}

.modal-filter-public {
  border: 0;
  border-right: 1px solid var(--border-color);
}

.modal-filter-active {
  color: #1da1f2;
}

.modal-no-result {
  border: 0;
  text-align: center;
  font-size: 14px;
  padding: 15px 0;
  display: flex;
  align-items: center;
  flex-direction: row;
}

@-webkit-keyframes modal-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes modal-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes modal-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes modal-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
